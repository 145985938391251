import React from "react";
import Nav from "./components/nav";
import { Wallet } from "./components/wallet";
import { Outlet } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faXTwitter,
  faDiscord,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faGlobe,
  faSpinner,
  faCheck,
  faXmark,
  faCircle,
  faParachuteBox,
  faCreditCard,
  faCircleQuestion,
  faSquarePlus,
  faSquareMinus,
  faArrowLeft,
  faBars,
  faGear,
  faCirclePlus,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faGlobe,
  faXTwitter,
  faDiscord,
  faTelegram,
  faSpinner,
  faCheck,
  faXmark,
  faCircle,
  faParachuteBox,
  faCreditCard,
  faCircleQuestion,
  faSquarePlus,
  faSquareMinus,
  faArrowLeft,
  faBars,
  faGear,
  faCirclePlus,
  faArrowsRotate
);

const App = () => {
  return (
    <Wallet>
      <div className="min-h-screen flex-grow">
        <Nav />
        <Outlet />
      </div>
    </Wallet>
  );
};
export default App;
