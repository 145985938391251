import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./assets/index.css";
import "./assets/wallet.css";
import App from "./App";
import Home from "./pages/home";
import Presale from "./pages/presale/index";
import PreProject from "./pages/presale/project";
import Airdrop from "./pages/airdrop/index";
import AirProject from "./pages/airdrop/project";
import Faq from "./pages/faq";
import Admin from "./pages/admin/index";
import Create from "./pages/admin/create";
import Project from "./pages/admin/project";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path="/presale" element={<Presale />} />
        <Route path="/presale/:slug" element={<PreProject />} />
        <Route path="/airdrop" element={<Airdrop />} />
        <Route path="/airdrop/:slug" element={<AirProject />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/airdrop/:slug" element={<Project />} />
        <Route path="/admin/presale/:slug" element={<Project />} />
        <Route path="/create" element={<Create />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
