import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Prebutton from "../../components/presale/button";
import Prebody from "../../components/presale/body";
import Partnerships from "../../components/presale/partnerships";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

export default function Preproject() {
  const { publicKey } = useWallet();
  let { slug } = useParams();

  const [presale, setPresale] = useState("loading");
  const [partners, setPartners] = useState();

  useEffect(() => {
    if (publicKey) {
      getProj();
    }
  }, [publicKey]);

  async function getProj() {
    try {
      let res = await fetch(`/api/presale/project?id=${slug}`);
      let resj = await res.json();
      setPresale(resj);

      let res2 = await fetch(`/api/presale/partner?id=${slug}`);
      let resj2 = await res2.json();
      setPartners(resj2);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="m-8">
      {publicKey ? (
        <>
          <div className="text-sm mb-4 text-t1">
            <Link to="/presale">
              <FontAwesomeIcon
                icon="fa-solid fa-arrow-left"
                size="xs"
                className="mr-1"
              />
              BACK
            </Link>
          </div>
          {presale === "loading" ? (
            <div className="flex items-center justify-center h-full animate-pulse">
              <FontAwesomeIcon
                icon="fa-solid fa-parachute-box"
                size="10x"
                flip
                className="mx-1 font-bold"
              />
            </div>
          ) : (
            <>
              {presale ? (
                <div className="flex flex-wrap lg:flex-nowrap gap-3">
                  <div className="lg:basis-3/5 w-full">
                    <Prebody presale={presale} />
                    <Prebutton presale={presale} />
                  </div>
                  <div className="lg:basis-2/5 w-full">
                    <Partnerships partners={partners} />
                  </div>
                </div>
              ) : (
                <>Not Found </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <WalletMultiButton />
        </>
      )}
    </div>
  );
}
