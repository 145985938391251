import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import logo from "../assets/logo.gif";

export default function Nav() {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <div className="bg-drk/30 shadow-sm backdrop-blur-sm flex">
        <div className="py-4 px-4 flex w-full justify-between items-center">
          <div className="flex items-center">
            <Link to="/" className="w-8 mr-2">
              <img src={logo} alt="CoynShopLogo" />
            </Link>
            <div className="hidden md:flex">
              <div className="">
                <NavLink to="/presale">
                  <div className="py-3 px-6 hover:bg-bg/60 hover:text-a1 font-bold">
                    PRESALE
                  </div>
                </NavLink>
              </div>
              <div className="">
                <NavLink to="/airdrop">
                  <div className="py-3 px-6 hover:bg-bg/60 hover:text-a1 font-bold">
                    AIRDROP
                  </div>
                </NavLink>
              </div>
              <div className="">
                <NavLink to="/admin">
                  <div className="py-3 px-6 hover:bg-bg/60 hover:text-a1 font-bold">
                    ADMIN
                  </div>
                </NavLink>
              </div>
              <div className="">
                <NavLink to="/faq">
                  <div className="py-3 px-6 hover:bg-bg/60 hover:text-a1 font-bold">
                    FAQ
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="hidden md:flex">
              <div className="text-sm">
                <a target="_blank" rel="noreferrer" href="https://discord.com">
                  <div className="px-2 hover:text-a1/60 ">
                    <FontAwesomeIcon
                      icon="fa-brands fa-discord"
                      className="mr-2"
                      size="sm"
                    />
                  </div>
                </a>
              </div>
              <div className="text-sm">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://x.com/coyndotshop">
                  <div className="px-2 hover:text-a1/60 ">
                    <FontAwesomeIcon
                      icon="fa-brands fa-x-twitter"
                      className="mr-2"
                      size="sm"
                    />
                  </div>
                </a>
              </div>
            </div>
            <div>
              <WalletMultiButton />
            </div>
            <div
              onClick={() => setShowMenu(!showMenu)}
              className="cursor-pointer md:hidden flex">
              <FontAwesomeIcon
                icon={showMenu ? "fa-solid fa-xmark" : "fa-solid fa-bars"}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`md:hidden absolute w-full z-50
        overflow-hidden transition-[height] ease-in-out duration-500
        ${showMenu ? "h-screen" : "h-0"}`}>
        <div className="bg-bg/90 backdrop-blur-lg justify-between flex flex-col p-4  w-full">
          <div className="flex flex-col w-full">
            <div className="w-full px-6 text-center">
              <NavLink to="/presale">
                {({ isActive }) => (
                  <div
                    className={
                      isActive
                        ? "bg-bg py-3 px-6 border-l-2 border-a1/60"
                        : "py-3 px-6 hover:bg-bg/60 hover:border-l-2 hover:border-a1/60 border-l-2 border-drk/0"
                    }
                    onClick={() => setShowMenu(!showMenu)}>
                    <FontAwesomeIcon
                      icon="fa-solid fa-credit-card"
                      className="mr-2"
                      size="sm"
                    />
                    PRESALE
                  </div>
                )}
              </NavLink>
            </div>
            <div className="w-full px-6 text-center">
              <NavLink to="/airdrop">
                {({ isActive }) => (
                  <div
                    className={
                      isActive
                        ? "bg-bg py-3 px-6 border-l-2 border-a1/60"
                        : "py-3 px-6 hover:bg-bg/60 hover:border-l-2 hover:border-a1/60 border-l-2 border-drk/0"
                    }
                    onClick={() => setShowMenu(!showMenu)}>
                    <FontAwesomeIcon
                      icon="fa-solid fa-parachute-box"
                      className="mr-2"
                      size="sm"
                    />
                    AIRDROP
                  </div>
                )}
              </NavLink>
            </div>
            <div className="w-full px-6 text-center">
              <NavLink to="/admin">
                {({ isActive }) => (
                  <div
                    className={
                      isActive
                        ? "bg-bg py-3 px-6 border-l-2 border-a1/60"
                        : "py-3 px-6 hover:bg-bg/60 hover:border-l-2 hover:border-a1/60 border-l-2 border-drk/0"
                    }
                    onClick={() => setShowMenu(!showMenu)}>
                    <FontAwesomeIcon
                      icon="fa-solid fa-gear"
                      className="mr-2"
                      size="sm"
                    />
                    ADMIN
                  </div>
                )}
              </NavLink>
            </div>
            <div className="w-full px-6 text-center">
              <NavLink to="/faq">
                {({ isActive }) => (
                  <div
                    className={
                      isActive
                        ? "w-full bg-bg py-3 px-6 border-l-2 border-a1/60"
                        : "py-3 px-6 hover:bg-bg/60 hover:border-l-2 hover:border-a1/60 border-l-2 border-drk/0"
                    }
                    onClick={() => setShowMenu(!showMenu)}>
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-question"
                      className="mr-2"
                      size="sm"
                    />
                    FAQ
                  </div>
                )}
              </NavLink>
            </div>
            <hr className="border-bg mx-6" />
            <div className="w-full px-6 text-sm text-center">
              <a target="_blank" rel="noreferrer" href="https://discord.com">
                <div
                  onClick={() => setShowMenu(!showMenu)}
                  className="py-3 px-6 hover:bg-bg/60 hover:border-l-2 hover:border-a1/60 border-l-2 border-drk/0">
                  <FontAwesomeIcon
                    icon="fa-brands fa-discord"
                    className="mr-2"
                    size="sm"
                  />
                  DISCORD
                </div>
              </a>
            </div>
            <div className="w-full px-6 text-sm text-center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://x.com/coyndotshop">
                <div
                  onClick={() => setShowMenu(!showMenu)}
                  className="py-3 px-6 hover:bg-bg/60 hover:border-l-2 hover:border-a1/60 border-l-2 border-drk/0">
                  <FontAwesomeIcon
                    icon="fa-brands fa-x-twitter"
                    className="mr-2"
                    size="sm"
                  />
                  TWITTER
                </div>
              </a>
            </div>
            <div className="w-full p-6 text-center">
              <WalletMultiButton />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
