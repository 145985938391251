import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Prebody({ presale }) {
  // Mapping function to get the FontAwesome icon based on the platform
  const getPlatformIcon = (platform) => {
    switch (platform) {
      case "twitter":
        return "fa-brands fa-x-twitter";
      case "discord":
        return "fa-brands fa-discord";
      case "telegram":
        return "fa-brands fa-telegram";
      case "website":
        return "fa-solid fa-globe";
      default:
        return null; // No icon for other platforms
    }
  };

  const x = presale.socials.filter((s) => s.platform === "twitter");

  return (
    <>
      <div className="flex flex-wrap gap-2 mb-1 items-center">
        <div className="text-2xl font-bold text-ellipsis overflow-hidden">
          {presale.name}
        </div>
        <div className="text-sm text-t1">({presale.symbol})</div>
      </div>
      <div className="flex gap-3 text-t1 mb-2">
        {presale.socials?.map((s) => (
          <a
            key={s.platform}
            className="hover:text-t2"
            target="_blank"
            rel="noreferrer"
            href={s.url}
          >
            <FontAwesomeIcon icon={getPlatformIcon(s.platform)} size="sm" />
          </a>
        ))}
      </div>

      <div className="rounded-lg flex gap-4 mb-4">
        <img
          src={presale.logo}
          alt="logo"
          className="rounded-lg border border-lght w-1/2"
        />
        <div className="flex flex-col gap-4 w-full">
          <div className="basis-1/3 bg-drk/30 border border-drk rounded-lg p-2 items-center flex flex-col justify-center">
            <div className="text-sm text-a1 ">OVERALL SUPPLY</div>
            <div className="font-light text-lg">
              {convertToShortScale(presale.supply, 0)}
            </div>
          </div>
          <div className="basis-1/3 bg-drk/30 border border-drk rounded-lg p-2 items-center flex flex-col justify-center">
            <div className="text-sm text-a1 ">PRESALE PRICE</div>
            <div className="font-light text-lg">{presale.price} SOL</div>
          </div>
          <div className="basis-1/3 bg-drk/30 border border-drk rounded-lg p-2 items-center flex flex-col justify-center">
            <div className="text-sm text-a1 ">LAUNCH DATE</div>
            <div className="font-light text-lg">{presale.launch}</div>
          </div>
        </div>
      </div>
      {presale.about && (
        <div className="w-full bg-drk/30 border border-drk rounded-lg p-3 mb-4">
          <div className="text-xs text-a1 ">ABOUT</div>
          <div className="font-light">{presale.about}</div>
        </div>
      )}
      {x.length > 0 && (
        <div className="w-full bg-drk/30 border border-drk rounded-lg p-3">
          <div className="text-xs text-a1 ">Requirements</div>
          <div className="font-light flex justify-between items-center mb-3">
            <div>Follow Coyn.Shop on Twitter</div>
            <div className="text-sm">
              <a
                href="https://x.com/coyn.shop"
                target="_blank"
                rel="noreferrer"
                className="px-2 py-1 hover:bg-lght hover:border-brdr border bg-drk rounded-lg items-center flex"
              >
                <FontAwesomeIcon
                  icon={"fa-brands fa-x-twitter"}
                  size="2xs"
                  className="mr-1"
                />
                FOLLOW
              </a>
            </div>
          </div>
          <div className="font-light flex justify-between items-center">
            <div>Follow {presale.name} on Twitter</div>
            <div className="text-sm">
              <a
                href={`${x[0].url}`}
                target="_blank"
                rel="noreferrer"
                className="px-2 py-1 hover:bg-lght hover:border-brdr border bg-drk rounded-lg items-center flex"
              >
                <FontAwesomeIcon
                  icon={"fa-brands fa-x-twitter"}
                  size="2xs"
                  className="mr-1"
                />
                FOLLOW
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const convertToShortScale = (num, decimals) => {
  let number = Math.floor(num / 10 ** decimals);
  const suffixes = ["", "k", "m", "b", "t"];
  const order = Math.min(
    Math.floor(Math.log10(Math.abs(number)) / 3),
    suffixes.length - 1
  );

  const roundedNumber = parseFloat(
    (number / Math.pow(10, order * 3)).toFixed(2)
  );

  return `${roundedNumber}${suffixes[order]}`;
};
