import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

export default function Admin() {
  const { publicKey } = useWallet();

  const [myA, setMyA] = useState([]);
  const [myP, setMyP] = useState([]);

  useEffect(() => {
    if (publicKey) {
      getProj();
    }
  }, [publicKey]);

  async function getProj() {
    try {
      let res = await fetch(
        `/api/airdrop/project?creator=${publicKey.toBase58()}`
      );
      let resj = await res.json();
      setMyA(resj);

      let res2 = await fetch(
        `/api/presale/project?creator=${publicKey.toBase58()}`
      );
      let resj2 = await res2.json();
      setMyP(resj2);
    } catch (error) {
      console.log(error);
    }
  }

  // Mapping function to get the FontAwesome icon based on the platform
  const getPlatformIcon = (platform) => {
    switch (platform) {
      case "twitter":
        return "fa-brands fa-x-twitter";
      case "discord":
        return "fa-brands fa-discord";
      case "telegram":
        return "fa-brands fa-telegram";
      case "website":
        return "fa-solid fa-globe";
      default:
        return null; // No icon for other platforms
    }
  };

  return (
    <div className="m-8">
      {publicKey ? (
        <>
          <div className="flex mb-4 justify-between items-center">
            <div className="text-2xl font-bold">My Projects</div>
            <div
              className="rounded-lg text-lg
            border-2 border-a1 hover:text-a1 hover:bg-lght 
            bg-a1 text-drk
            duration-500 ease-in-out transition-all"
            >
              <Link to="/create" className="py-1 px-3 flex items-center">
                <FontAwesomeIcon
                  icon="fa-solid fa-circle-plus"
                  size="sm"
                  className="mr-2"
                />
                CREATE
              </Link>
            </div>
          </div>
          <div className="flex flex-wrap gap-4">
            {myP?.map((d) => (
              <div
                key={d.slug}
                className="relative lg:basis-1/3-gap-4 md:basis-1/2-gap-4 w-full
            border border-lght bg-drk/30 backdrop-blur-sm 
           hover:border-a1 hover:border rounded-lg"
              >
                <div className="text-xs gap-2 flex justify-end p-0.5 mr-2">
                  {d.socials.map((s) => (
                    <a
                      key={s.platform}
                      target="_blank"
                      rel="noreferrer"
                      href={s.url}
                      className="rounded-full p-1"
                    >
                      <FontAwesomeIcon icon={getPlatformIcon(s.platform)} />
                    </a>
                  ))}
                </div>
                <Link to={`presale/${d.slug}`}>
                  <div className="flex bg-drk/30 justify-center px-8 py-4 rounded-t-lg">
                    <img
                      src={d.logo}
                      alt="logo"
                      className="w-52 rounded-full"
                    />
                  </div>
                  <div className="p-2 space-y-1">
                    <div
                      className="flex-auto px-3 py-2 space-y-1 text-lg font-medium 
                  line-clamp-1 break-all"
                    >
                      {d.name}
                    </div>
                    <div className="flex mt-2 justify-end items-center text-sm w-full text-t1">
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle"
                        size="2xs"
                        className={`text-2xs mr-2 ${
                          d.status === 1
                            ? "text-gr animate-pulse duration-700"
                            : "text-rd"
                        }`}
                      />
                      {d.status === 1 ? <>Open</> : <>Closed</>}
                    </div>
                  </div>
                </Link>
              </div>
            ))}
            {myA?.map((d) => (
              <div
                key={d.slug}
                className="relative lg:basis-1/3-gap-4 md:basis-1/2-gap-4 w-full
            border border-lght bg-drk/30 backdrop-blur-sm 
           hover:border-a1 hover:border rounded-lg"
              >
                <div className="text-xs gap-2 flex justify-end p-0.5 mr-2">
                  {d.socials.map((s) => (
                    <a
                      key={s.platform}
                      target="_blank"
                      rel="noreferrer"
                      href={s.url}
                      className="rounded-full p-1"
                    >
                      <FontAwesomeIcon icon={getPlatformIcon(s.platform)} />
                    </a>
                  ))}
                </div>
                <Link to={`airdrop/${d.slug}`}>
                  <div className="flex bg-drk/30 justify-center px-8 py-4 rounded-t-lg">
                    <img
                      src={d.logo}
                      alt="logo"
                      className="w-52 rounded-full"
                    />
                  </div>
                  <div className="p-2 space-y-1">
                    <div
                      className="flex-auto px-3 py-2 space-y-1 text-lg font-medium 
                  line-clamp-1 break-all"
                    >
                      {d.name}
                    </div>
                    <div className="flex mt-2 justify-end items-center text-sm w-full text-t1">
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle"
                        size="2xs"
                        className={`text-2xs mr-2 ${
                          d.status === 1
                            ? "text-gr animate-pulse duration-700"
                            : "text-rd"
                        }`}
                      />
                      {d.status === 1 ? <>Open</> : <>Closed</>}
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <WalletMultiButton />
        </>
      )}
    </div>
  );
}
