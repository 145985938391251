import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Faq() {
  const [price, setPrice] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let url2 = `/api/admin/info`;
        const response2 = await fetch(url2);
        const data2 = await response2.json();
        setPrice(data2.createPrice);
      } catch (error) {
        console.log(error);
      } // Call the async function
    };
    fetchData();
  }, [price]);

  const faqs = [
    {
      q: "What is Coyn.Shop?",
      a: "Coyn.Shop is about empowering SOLANA users & projects. We are building a suite of tools to make the average degen's life much simpler, starting with an airdrop & presale platform.",
    },
    {
      q: "How does it empower users??",
      a: "Coyn.Shop works completely different from all our competitors. We offer full transparency, letting you see how much allocation each project received and a single view for all your eligibility, with minimal effort.",
    },
    {
      q: "Do I have to do anything to claim?",
      a: "Nope, simply connect your wallet and interact with the project. You will get prompted if you're eligible or not. It's that straightforward.",
    },
    {
      q: "Why should a project use Coyn.Shop?",
      a: `We work differently. Coyn.Shop takes a fixed fee  (${price} SOL for now) instead of the usual % on other platforms. It's also super easy and intuitive to set everything up.`,
    },
    {
      q: "How do I launch my project?",
      a: 'To launch your token, go to the <a href="/admin" style="text-decoration: underline;">Admin</a> page or reach out to us on  <a target="_blank" rel="noreferrer" href="https://x.com/coyndotshop" style="text-decoration: underline;">Twitter</a> or <a target="_blank" rel="noreferrer" href="https://discord.com/coyndotshop" style="text-decoration: underline;">Discord</a>.',
    },
    {
      q: "Who built this application?",
      a: 'Built by <a href="https://x.com/amusnation" target="_blank" rel="noreferrer">Amus</a> and is still in BETA.',
    },
  ];

  const [expandedIndex, setExpandedIndex] = useState(0);

  const toggleAccordion = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="m-8">
      <div className="font-bold text-2xl">Frequently Asked Questions</div>
      {faqs.map((faq, index) => (
        <div
          key={index}
          className="p-4 my-4 rounded-lg bg-drk/30 backdrop-blur-sm border border-lght/30"
        >
          <div
            className="cursor-pointer items-center flex my-2"
            onClick={() => toggleAccordion(index)}
          >
            <div>
              <FontAwesomeIcon
                icon={`${
                  expandedIndex === index
                    ? "fa-solid fa-square-minus"
                    : "fa-solid fa-square-plus"
                }`}
                className="mr-2 text-a1"
                size="sm"
              />
            </div>
            <div className="font-bold text-lg">{faq.q}</div>
          </div>
          <div
            className={` font-light
            overflow-hidden transition-[max-height] duration-500 ease-in-out ${
              expandedIndex === index ? "max-h-96" : "max-h-0"
            }`}
          >
            <div dangerouslySetInnerHTML={{ __html: faq.a }} />
          </div>
        </div>
      ))}
    </div>
  );
}
