import { useState, useMemo, useCallback } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { Connection, Transaction } from "@solana/web3.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Airbutton({ airproj }) {
  const rpc = process.env.REACT_APP_RPC_URL;
  const connection = useMemo(
    () => new Connection(rpc, { commitment: "confirmed" }),
    [rpc]
  );

  const { publicKey, sendTransaction } = useWallet();

  const [error, setError] = useState();
  const [status, setStatus] = useState();
  const [sig, setSig] = useState();

  const air = useCallback(async () => {
    setStatus("processing");
    try {
      const url = `/api/airdrop/tx`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          CS_KEY: process.env.REACT_APP_CS_KEY,
        },
        body: JSON.stringify({
          slug: airproj.slug,
          wallet: publicKey.toBase58(),
        }),
      });

      const tx = await response.json();

      if (!response.ok) {
        setStatus("failed");
        setError(tx.message);
        // If response.ok is false, it means there was an HTTP error (status in the range 400-599)
        throw new Error(`${tx.message}`);
      }

      const txx = Transaction.from(Buffer.from(tx.wireTx, "base64"));

      let signature = await sendTransaction(txx, connection);

      const url2 = `/api/airdrop/tx`;

      const dbTx = await fetch(url2, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          CS_KEY: process.env.REACT_APP_CS_KEY,
        },
        body: JSON.stringify({
          txId: tx.tx,
          txHash: signature,
        }),
      });

      if (!dbTx.ok) {
        await fetch(url2, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            CS_KEY: process.env.REACT_APP_CS_KEY,
          },
          body: JSON.stringify({
            txId: tx.tx,
            txHash: signature,
          }),
        });
      }

      setStatus("successful");
      setSig(signature);
    } catch (error) {
      setStatus("failed");
      setError(error.message);
      console.log(error);
    }
  }, [connection, airproj.slug, publicKey, sendTransaction]);

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const handleCheckboxChange = () => {
    setIsButtonEnabled(!isButtonEnabled);
  };

  return (
    <>
      <div className="mt-6">
        <div className="my-2">
          <label className="flex items-center text-sm text-t2">
            <input
              type="checkbox"
              className="form-checkbox mr-2"
              checked={isButtonEnabled}
              onChange={handleCheckboxChange}
            />
            I take full responsibility for my actions and have done my research.
          </label>
        </div>
        <button
          className={`py-3 w-full rounded-lg text-xl font-bold border-2
          ${
            isButtonEnabled
              ? "border-a1 text-a1 bg-lght hover:bg-a1 hover:border-a1 hover:text-drk"
              : "bg-drk border-t1 text-t1 cursor-not-allowed"
          }
          duration-500 ease-in-out transition-all`}
          onClick={air}
          disabled={!publicKey || !isButtonEnabled}
        >
          {status === "processing" ? (
            <>
              <FontAwesomeIcon
                icon="fa-solid fa-spinner"
                spin
                size="sm"
                className="mr-4 font-bold"
              />
              PROCESSING
            </>
          ) : (
            <>
              AIRDROP
              <FontAwesomeIcon
                icon="fa-solid fa-parachute-box"
                className="ml-4"
                size="sm"
              />
            </>
          )}
        </button>
        <div className="text-t1/50 text-end">
          Token Address:{" "}
          <a
            href={`https://solscan.io/token/${airproj.wallet}`}
            target="_blank"
            rel="noreferrer"
            className="text-t1 hover:text-t2"
          >
            {airproj.wallet.substring(0, 4)}..
            {airproj.wallet.substring(airproj.wallet.length - 4)}
          </a>
        </div>
      </div>

      {status === "successful" && (
        <div className="fixed bottom-4 right-4 flex rounded-lg border-drk border">
          <div className="bg-gr/50 py-3 px-4 rounded-l-lg flex items-center">
            <FontAwesomeIcon icon="fa-solid fa-check" size="lg" />
          </div>
          <div
            className="px-3 py-4 bg-drk/30 backdrop-blur-sm 
            rounded-r-lg flex justify-between items-center w-full "
          >
            <div className="px-2">
              Transaction successful. View on{" "}
              <a
                href={`https://solscan.io/tx/${sig}`}
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                Explorer
              </a>
            </div>
            <button onClick={() => setStatus(null)}>
              <FontAwesomeIcon icon="fa-solid fa-xmark" />
            </button>
          </div>
        </div>
      )}
      {status === "failed" && (
        <div className="fixed bottom-4 right-4 flex rounded-lg border-drk border">
          <div className="bg-rd/50 py-3 px-4 rounded-l-lg flex items-center">
            <FontAwesomeIcon icon="fa-solid fa-xmark" size="lg" />
          </div>
          <div
            className="px-3 py-4 bg-drk/30 backdrop-blur-sm 
            rounded-r-lg flex justify-between items-center w-full "
          >
            <div className="px-2">Transaction failed. {error}</div>
            <button onClick={() => setStatus(null)}>
              <FontAwesomeIcon icon="fa-solid fa-xmark" />
            </button>
          </div>
        </div>
      )}
    </>
  );
}
