import { useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { SystemProgram, Transaction, Connection } from "@solana/web3.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PublicKey } from "@solana/web3.js";

export default function Create() {
  const { publicKey, sendTransaction } = useWallet();
  const rpc = process.env.REACT_APP_RPC_URL;
  const connection = useMemo(
    () => new Connection(rpc, { commitment: "confirmed" }),
    [rpc]
  );

  const navigate = useNavigate();

  const [type, setType] = useState("presale");

  const [paid, setPaid] = useState(false);

  const [status, setStatus] = useState();

  const [formData, setFormData] = useState({
    slug: "",
    creator: "",
    wallet: "",
    name: "",
    symbol: "",
    about: "",
    logo: "",
    supply: "",
    price: "", // only ps
    limit: "", // only ps
    currency: "SOL", // only ps
    decimals: "", // only token
    type: "",
    launch: "",
    socials: [{ platform: "", url: "" }],
  });

  useEffect(() => {
    if (publicKey) {
      setFormData({
        ...formData,
        creator: publicKey.toBase58(),
      });
      checkPaid();
    }
  }, [publicKey]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validation regex
    const validationRegex = /^[a-zA-Z0-9]+$/;

    if (
      (name === "slug" || name === "wallet") &&
      !validationRegex.test(value) &&
      value !== ""
    ) {
      // Invalid input, don't update the state
      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSocialsChange = (e, index) => {
    const { name, value } = e.target;
    const newSocials = [...formData.socials];
    newSocials[index][name] = value;
    setFormData({
      ...formData,
      socials: newSocials,
    });
  };

  const handleAddSocial = () => {
    setFormData({
      ...formData,
      socials: [...formData.socials, { platform: "", url: "" }],
    });
  };

  const handleRemoveSocial = (index) => {
    const newSocials = [...formData.socials];
    newSocials.splice(index, 1);
    setFormData({
      ...formData,
      socials: newSocials,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setStatus("processing");

    setFormData({
      ...formData,
      creator: publicKey.toBase58(),
    });

    let url = `/api/${type}/project`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          CS_KEY: process.env.REACT_APP_CS_KEY,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        setStatus("failed");
        // If response.ok is false, it means there was an HTTP error (status in the range 400-599)
        throw new Error(`${response.message}`);
      }

      const data = await response.json();

      setStatus("successful");
      navigate(`/admin/${type}/${formData.slug}`);
    } catch (error) {
      console.log(error);
      setStatus("failed");
    }
  };

  async function checkPaid() {
    let url = `/api/admin/payment?wallet=${publicKey.toBase58()}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (response.ok && data.length > 0) {
        setPaid(true);
      } else {
        setPaid(false);
      }
    } catch (error) {
      console.log(error);
      setStatus("failed");
    }
  }

  const pay = async () => {
    setStatus("processing");

    try {
      let url2 = `/api/admin/info`;
      const response2 = await fetch(url2);
      const data2 = await response2.json();
      let toWP = new PublicKey(data2.toWallet);
      const lamports = data2.createPrice * 1_000_000_000;

      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: toWP,
          lamports: lamports,
        })
      );

      let signature = await sendTransaction(transaction, connection);

      let url = `/api/admin/payment`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          CS_KEY: process.env.REACT_APP_CS_KEY,
        },
        body: JSON.stringify({ tx: signature }),
      });

      if (!response.ok) {
        setStatus("failed");
        // If response.ok is false, it means there was an HTTP error (status in the range 400-599)
        throw new Error(`${response.message}`);
      }
      const data = await response.json();

      setStatus(data.message);
      setPaid(true);
    } catch (error) {
      setStatus("failed");
      console.log(error);
    }
  };

  return (
    <div className="m-8">
      {publicKey ? (
        <>
          {paid ? (
            <>
              <div className="text-sm mb-4 text-t1">
                <Link to="/admin">
                  <FontAwesomeIcon
                    icon="fa-solid fa-arrow-left"
                    size="xs"
                    className="mr-1"
                  />
                  BACK
                </Link>
              </div>
              <div className="flex text-center mb-4">
                <button
                  className={`w-1/2  border-a1 border-r-1 border-y-2 border-l-2
            rounded-l-lg p-2
            ${type === "presale" ? "bg-a1 text-drk" : "bg-drk hover:bg-lght"}`}
                  onClick={() => setType("presale")}
                >
                  PRESALE
                </button>
                <button
                  className={`w-1/2 border-a1 border-r-2 border-y-2 border-l-1
            rounded-r-lg p-2
            ${type === "airdrop" ? "bg-a1 text-drk" : "bg-drk hover:bg-lght"}
            `}
                  onClick={() => setType("airdrop")}
                >
                  AIRDROP
                </button>
              </div>
              <form
                onSubmit={handleSubmit}
                className="pb-4 flex flex-wrap gap-4"
              >
                <div className="mb-4 md:basis-1/2-gap-4">
                  <label className="block text-t1 text-sm font-bold mb-2">
                    Project ID
                  </label>
                  <input
                    type="text"
                    name="slug"
                    value={formData.slug}
                    onChange={handleChange}
                    className="w-full border rounded px-3 py-2 text-black"
                    required
                  />
                </div>
                <div className="mb-4 md:basis-1/2-gap-4">
                  <label className="block text-t1 text-sm font-bold mb-2">
                    {type === "presale" ? (
                      <>Presale Wallet</>
                    ) : (
                      <>Token Address</>
                    )}
                  </label>
                  <input
                    type="text"
                    name="wallet"
                    value={formData.wallet}
                    onChange={handleChange}
                    className="w-full border rounded px-3 py-2 text-black"
                    required
                  />
                </div>
                {type === "presale" && (
                  <>
                    <div className="mb-4 md:basis-1/2-gap-4">
                      <label className="block text-t1 text-sm font-bold mb-2">
                        Project Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full border rounded px-3 py-2 text-black"
                      />
                    </div>
                    <div className="mb-4 md:basis-1/2-gap-4">
                      <label className="block text-t1 text-sm font-bold mb-2">
                        Symbol
                      </label>
                      <input
                        type="text"
                        name="symbol"
                        value={formData.symbol}
                        onChange={handleChange}
                        className="w-full border rounded px-3 py-2 text-black"
                      />
                    </div>
                    <div className="mb-4 w-full">
                      <label className="block text-t1 text-sm font-bold mb-2">
                        About
                      </label>
                      <input
                        type="text"
                        name="about"
                        value={formData.about}
                        onChange={handleChange}
                        className="w-full border rounded px-3 py-2 text-black"
                      />
                    </div>
                    <div className="mb-4 md:basis-1/2-gap-4">
                      <label className="block text-t1 text-sm font-bold mb-2">
                        Logo URL
                      </label>
                      <input
                        type="text"
                        name="logo"
                        value={formData.logo}
                        onChange={handleChange}
                        className="w-full border rounded px-3 py-2 text-black"
                      />
                    </div>
                    <div className="mb-4 md:basis-1/2-gap-4">
                      <label className="block text-t1 text-sm font-bold mb-2">
                        Overall Supply
                      </label>
                      <input
                        type="number"
                        name="supply"
                        value={formData.supply}
                        onChange={handleChange}
                        className="w-full border rounded px-3 py-2 text-black"
                      />
                    </div>
                    <div className="mb-4 md:basis-1/2-gap-4">
                      <label className="block text-t1 text-sm font-bold mb-2">
                        Presale Price
                      </label>
                      <input
                        type="number"
                        name="price"
                        value={formData.price}
                        onChange={handleChange}
                        className="w-full border rounded px-3 py-2 text-black"
                      />
                    </div>
                    <div className="mb-4 md:basis-1/2-gap-4">
                      <label className="block text-t1 text-sm font-bold mb-2">
                        Limit Per Wallet
                      </label>
                      <input
                        type="number"
                        name="limit"
                        value={formData.limit}
                        onChange={handleChange}
                        className="w-full border rounded px-3 py-2 text-black"
                      />
                    </div>
                  </>
                )}
                <div className="mb-4 md:basis-1/2-gap-4">
                  <label className="block text-t1 text-sm font-bold mb-2">
                    Project Type
                  </label>
                  <select
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    className="w-full border rounded px-3 py-2 text-black"
                  >
                    <option value="" disabled>
                      Select a type
                    </option>
                    <option value="NFT">NFT</option>
                    <option value="SPL">SPL</option>
                    <option value="Token22">Token22</option>
                    {/* Add more options as needed */}
                  </select>
                </div>
                <div className="mb-4 md:basis-1/2-gap-4">
                  <label className="block text-t1 text-sm font-bold mb-2">
                    Launch Date
                  </label>
                  <input
                    type="text"
                    name="launch"
                    value={formData.launch}
                    onChange={handleChange}
                    className="w-full border rounded px-3 py-2 text-black"
                  />
                </div>
                <div className="mb-4 w-full">
                  <label className="block text-t1 text-sm font-bold mb-2">
                    Social URLs
                  </label>
                  {formData.socials.map((social, index) => (
                    <div key={index} className="mb-4 flex">
                      <div className="">
                        <select
                          name="platform"
                          value={social.platform}
                          onChange={(e) => handleSocialsChange(e, index)}
                          className="w-min h-full border-l border-y rounded-l px-3 py-2 text-black"
                        >
                          <option value="" disabled>
                            Platform
                          </option>
                          <option value="twitter">Twitter</option>
                          <option value="discord">Discord</option>
                          <option value="telegram">Telegram</option>
                          <option value="website">Website</option>
                        </select>
                      </div>
                      <div className="w-full">
                        <input
                          type="text"
                          name="url"
                          value={social.url}
                          onChange={(e) => handleSocialsChange(e, index)}
                          className="w-full border-r border-y rounded-r px-3 py-2 text-black"
                        />
                      </div>
                      <button
                        type="button"
                        onClick={() => handleRemoveSocial(index)}
                        className="ml-2 bg-red-500 text-white px-4 py-2 rounded"
                      >
                        -
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={handleAddSocial}
                    className="bg-lght hover:bg-drk text-white px-4 py-2 rounded"
                  >
                    Add Social
                  </button>
                </div>

                <div className="mb-4">
                  <button
                    type="submit"
                    className="rounded-lg text-lg flex items-center
                  border-2 border-a1 hover:text-a1 hover:bg-lght 
                 bg-a1 text-drk py-1 px-3
                  duration-500 ease-in-out transition-all"
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-plus"
                      size="sm"
                      className="mr-2"
                    />
                    CREATE
                  </button>
                </div>
              </form>
              {status === "processing" && <>Processing</>}
              {status === "successful" && <>Completed</>}
              {status === "failed" && <>Failed</>}
            </>
          ) : (
            <>
              <div className="text-2xl mb-4">Insert Coin</div>
              <div className="mb-4">
                <button
                  onClick={pay}
                  className="rounded-lg text-lg flex items-center
                  border-2 border-a1 hover:text-a1 hover:bg-lght 
                 bg-a1 text-drk py-1 px-4
                  duration-500 ease-in-out transition-all"
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-circle-plus"
                    size="sm"
                    className="mr-2"
                  />
                  Pay
                </button>
              </div>
              <div className="text-t1">{status}</div>
            </>
          )}
        </>
      ) : (
        <>
          <WalletMultiButton />
        </>
      )}
    </div>
  );
}
