export default function Partnerships({ partners, symbol }) {
  const sp = partners?.sort((a, b) => b.available - a.available);

  return (
    <div className="md:basis-1/2-gap-4 w-full p-3 border border-drk bg-drk/30 backdrop-blur-sm rounded-lg">
      <div className="text-a1 text-xs mb-3">PARTNERSHIPS</div>
      {sp?.map((c, i) => (
        <div key={i} className="flex text-sm justify-between">
          <div className="flex gap-2 items-center basis-6/12">
            <div>
              <img src={c.image} alt="logo" className="h-4 rounded-full w-4" />
            </div>
            <div>{c.name}</div>
          </div>
          <div className="basis-3/12">
            {convertToShortScale(c.tokens)}{" "}
            <span className=" text-t2 text-xs">{symbol}</span>
          </div>
          <div className="flex gap-2 items-center basis-3/12 justify-end">
            <div className=" text-a1">
              {c.available}{" "}
              <span className=" text-t1 text-xs">/ {c.supply}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

const convertToShortScale = (number) => {
  const suffixes = ["", "k", "m", "b", "t"];
  const order = Math.min(
    Math.floor(Math.log10(Math.abs(number)) / 3),
    suffixes.length - 1
  );

  const roundedNumber = parseFloat(
    (number / Math.pow(10, order * 3)).toFixed(2)
  );

  return `${roundedNumber}${suffixes[order]}`;
};
