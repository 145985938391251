import { useEffect, useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

export default function Adminproject() {
  const { publicKey } = useWallet();
  let { slug } = useParams();

  const location = useLocation();

  let type = "";

  if (location.pathname.includes("/presale/")) type = "presale";
  if (location.pathname.includes("/airdrop/")) type = "airdrop";

  const [proj, setProj] = useState();
  const [partners, setPartners] = useState();
  const [balance, setBalance] = useState();
  const [astatus, setaStatus] = useState();
  const [status, setStatus] = useState();
  const [error, setError] = useState();
  const [addType, setAddType] = useState("partner");
  const [isWl, setIsWl] = useState();

  useEffect(() => {
    if (publicKey) {
      getProj();
    }
  }, [publicKey]);

  async function getProj() {
    try {
      let res = await fetch(`/api/${type}/project?id=${slug}`);
      let resj = await res.json();
      setProj(resj);

      if (publicKey.toBase58() === resj.creator) {
        let res2 = await fetch(`/api/${type}/partner?id=${slug}`);
        let resj2 = await res2.json();
        let wl = resj2.filter((r) => r.wallet === "c.swhitelist");
        setIsWl(wl.length);
        setPartners(resj2);
        if (type === "airdrop") {
          let res3 = await fetch(`/api/airdrop/balance?id=${slug}`);
          let resj3 = await res3.json();
          setBalance(resj3.balance);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getPartner() {
    try {
      let res2 = await fetch(`/api/${type}/partner?id=${slug}`);
      let resj2 = await res2.json();
      let wl = resj2.filter((r) => r.wallet === "c.swhitelist");
      setIsWl(wl.length);
      setPartners(resj2);
    } catch (error) {
      console.log(error);
    }
  }

  const [formData, setFormData] = useState({
    id: "",
    supply: "",
    tokens: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validation regex
    const validationRegex = /^[a-zA-Z0-9_]+$/;

    if (name === "id" && !validationRegex.test(value) && value !== "") {
      // Invalid input, don't update the state
      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setStatus("processing");

    let id = "";

    if (addType === "public") {
      id = "c.spublic";
    } else if (addType === "whitelist") {
      id = "c.swhitelist";
    } else {
      id = formData.id;
    }

    try {
      let url = `/api/${type}/partner`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          CS_KEY: process.env.REACT_APP_CS_KEY,
        },
        body: JSON.stringify({
          slug: slug,
          partner: id,
          supply: formData.supply,
          tokens: formData.tokens,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        setStatus("failed");
        setError(data.message);
        // If response.ok is false, it means there was an HTTP error (status in the range 400-599)
        throw new Error(`${data.message}`);
      } else if (response.status === 404) {
        // Handle 404 error
        setError("Not Found: The requested resource could not be found.");
      } else {
        setTimeout(() => {
          setStatus("completed");
          setFormData({
            id: "",
            supply: "",
            tokens: "",
          });
          getPartner();
        }, 4000);
      }
    } catch (error) {
      console.log(error);
      setStatus("failed");
      setError(error.message);
    }
  };

  async function activateProj() {
    setaStatus("processing");
    try {
      let url = `/api/${type}/project`;
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          CS_KEY: process.env.REACT_APP_CS_KEY,
        },
        body: JSON.stringify({
          slug: slug,
          wallet: publicKey.toBase58(),
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        setaStatus("failed");
        setError(data.message);
        // If response.ok is false, it means there was an HTTP error (status in the range 400-599)
        throw new Error(`${data.message}`);
      } else if (response.status === 404) {
        // Handle 404 error
        setError("Not Found: The requested resource could not be found.");
      } else {
        setaStatus("completed");
      }
    } catch (error) {
      console.log(error);
      setaStatus("failed");
      setError(error.message);
    }
  }

  const [wlValue, setWlValue] = useState("");

  const handleWlChange = (e) => {
    setWlValue(e.target.value);
  };

  const handleWlSubmit = async (e) => {
    e.preventDefault();
    // Convert the input value into an array by splitting on newline
    const resultArray = wlValue.split("\n").map((item) => item.trim());
    const arr = resultArray.filter((str) => str.trim() !== "");
    setStatus("processing");
    try {
      let url = `/api/${type}/wl`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          CS_KEY: process.env.REACT_APP_CS_KEY,
        },
        body: JSON.stringify({
          slug: slug,
          wallet: arr,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        setStatus("failed");
        setError(data.message);
        // If response.ok is false, it means there was an HTTP error (status in the range 400-599)
        throw new Error(`${data.message}`);
      } else if (response.status === 404) {
        // Handle 404 error
        setError("Not Found: The requested resource could not be found.");
      } else {
        setWlValue("");
        setStatus("completed");
      }
    } catch (error) {
      console.log(error);
      setStatus("failed");
      setError(error.message);
    }
  };

  return (
    <div className="m-8">
      {publicKey ? (
        <>
          <div className="text-sm mb-4 text-t1">
            <Link to="/admin">
              <FontAwesomeIcon
                icon="fa-solid fa-arrow-left"
                size="xs"
                className="mr-1"
              />
              BACK
            </Link>
          </div>
          {publicKey.toBase58() === proj?.creator ? (
            <>
              <div className="flex flex-wrap gap-2 mb-1 items-center">
                <div className="text-2xl font-bold text-ellipsis overflow-hidden">
                  {proj?.name}
                </div>
              </div>
              <div className="">
                <div className="flex gap-2">
                  <div className="text-t1">Airdrop balance:</div> {balance}{" "}
                  {proj?.symbol}
                </div>
                <div className="flex gap-2">
                  <div className="text-t1"> Send {proj?.symbol} to:</div>{" "}
                  {process.env.REACT_APP_SYS_WALLET}
                </div>
              </div>
              <div className="flex gap-2 mb-4 items-center">
                <div className="text-t1">Project status:</div>
                <div>
                  {proj?.status === 0 ? (
                    <div className="text-rd">Inactive</div>
                  ) : (
                    <div className="text-gr">Active</div>
                  )}
                </div>
                <div>
                  {proj?.status === 0 && (
                    <button
                      onClick={activateProj}
                      className="rounded-lg text-sm px-2 py-1 bg-a1 text-drk
                        border-2 border-a1 hover:text-a1 hover:bg-lght
                        duration-500 ease-in-out transition-all flex items-center"
                    >
                      ACTIVATE
                    </button>
                  )}
                </div>
                {astatus === "failed" && (
                  <div className="my-2 text-rd">Failed</div>
                )}
                {astatus === "completed" && (
                  <div className="my-2 text-gr">Activated</div>
                )}
                {astatus === "processing" && (
                  <div className="my-2 text-t1">Processing..</div>
                )}
              </div>

              <div className="md:basis-1/2-gap-4 w-full p-3 border border-drk bg-drk/30 backdrop-blur-sm rounded-lg">
                <div className="text-a1 text-xs mb-3">PARTNERSHIPS</div>
                {partners?.map((c, i) => (
                  <div key={i} className="flex text-sm">
                    <div className="flex gap-2 items-center basis-6/12">
                      <div>
                        <img
                          src={c.image}
                          alt="logo"
                          className="h-4 rounded-full w-4"
                        />
                      </div>
                      <div>{c.name}</div>
                    </div>
                    {c.tokens && (
                      <div className="flex gap-2 items-center basis-3/12 justify-end">
                        <div className="">{convertToShortScale(c.tokens)}</div>
                      </div>
                    )}
                    <div className="flex gap-2 items-center basis-3/12 justify-end">
                      <div className=" text-a1">
                        {c.available}{" "}
                        <span className=" text-t1 text-xs">/ {c.supply}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="my-4">
                <div className="text-xl">Add Partner</div>

                <div className="flex text-center mb-4">
                  <button
                    className={`w-1/3  border-a1 border-r-1 border-y-2 border-l-2 rounded-l-lg p-2
                    ${
                      addType === "public"
                        ? "bg-a1 text-drk"
                        : "bg-drk hover:bg-lght"
                    }`}
                    onClick={() => setAddType("public")}
                  >
                    PUBLIC
                  </button>
                  <button
                    className={`w-1/3 border-a1 border-y-2 border-l-1 p-2
                    ${
                      addType === "whitelist"
                        ? "bg-a1 text-drk"
                        : "bg-drk hover:bg-lght"
                    }`}
                    onClick={() => setAddType("whitelist")}
                  >
                    WHITELIST
                  </button>
                  <button
                    className={`w-1/3 border-a1 border-r-2 border-y-2 border-l-1 rounded-r-lg p-2
                    ${
                      addType === "partner"
                        ? "bg-a1 text-drk"
                        : "bg-drk hover:bg-lght"
                    }`}
                    onClick={() => setAddType("partner")}
                  >
                    PARTNER
                  </button>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="flex gap-4 w-full">
                    {addType === "partner" && (
                      <div className="mb-4 w-full">
                        <label className="block text-t1 text-sm font-bold mb-2">
                          Magic Eden ID:
                        </label>
                        <input
                          type="text"
                          placeholder="Magic Eden URL ID"
                          name="id"
                          value={formData.id}
                          onChange={handleChange}
                          className="w-full border rounded px-3 py-2 text-black"
                          required
                        />
                      </div>
                    )}
                    <div className="mb-4 w-full">
                      <label className="block text-t1 text-sm font-bold mb-2">
                        Supply:
                      </label>
                      <input
                        type="number"
                        placeholder="Number of spots"
                        name="supply"
                        value={formData.supply}
                        onChange={handleChange}
                        className="w-full border rounded px-3 py-2 text-black"
                        required
                      />
                    </div>
                    {type === "airdrop" && (
                      <div className="mb-4 w-full">
                        <label className="block text-t1 text-sm font-bold mb-2">
                          Airdrop Amount:
                        </label>
                        <input
                          type="number"
                          placeholder="Number of Tokens to Airdrop"
                          name="tokens"
                          value={formData.tokens}
                          onChange={handleChange}
                          className="w-full border rounded px-3 py-2 text-black"
                          required
                        />
                      </div>
                    )}
                  </div>
                  <div className="">
                    <button
                      type="submit"
                      className="rounded-lg text-lg px-3 py-1
            border-2 border-a1 hover:text-a1 hover:bg-lght 
            bg-a1 text-drk
            duration-500 ease-in-out transition-all flex items-center"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-plus"
                        size="xs"
                        className="mr-2"
                      />
                      ADD
                    </button>
                  </div>
                </form>
              </div>
              {status === "failed" && (
                <div className="my-2 text-rd">{error}</div>
              )}
              {status === "completed" && (
                <div className="my-2 text-gr">Added Successfully.</div>
              )}
              {status === "processing" && (
                <div className="my-2 text-t1">Processing..</div>
              )}
              {isWl > 0 && (
                <>
                  <div className="text-xl mb-2">Add Wallets To WL</div>
                  <textarea
                    rows="5"
                    placeholder={`Enter text on each line, e.g.${"\n"}wallet1${"\n"}wallet2${"\n"}wallet3`}
                    className="w-full border rounded px-3 py-2 text-black"
                    value={wlValue}
                    onChange={handleWlChange}
                  />
                  <button
                    onClick={handleWlSubmit}
                    className="border border-a1 px-2 py-1 bg-lght rounded-lg"
                  >
                    ADD TO WL
                  </button>
                </>
              )}
            </>
          ) : (
            <>No access</>
          )}
        </>
      ) : (
        <>
          <WalletMultiButton />
        </>
      )}
    </div>
  );
}

const convertToShortScale = (number) => {
  const suffixes = ["", "k", "m", "b", "t"];
  const order = Math.min(
    Math.floor(Math.log10(Math.abs(number)) / 3),
    suffixes.length - 1
  );

  const roundedNumber = parseFloat(
    (number / Math.pow(10, order * 3)).toFixed(2)
  );

  return `${roundedNumber}${suffixes[order]}`;
};
