import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

export default function Presale() {
  const { publicKey } = useWallet();

  const [presale, setPresale] = useState([]);
  const [status, setStatus] = useState("loading");

  useEffect(() => {
    if (publicKey) {
      getProj();
    }
  }, [publicKey]);

  async function getProj() {
    try {
      let res = await fetch(`/api/presale/project`);
      let resj = await res.json();
      setPresale(resj);
      setStatus("done");
    } catch (error) {
      console.log(error);
      setStatus("done");
    }
  }

  // Mapping function to get the FontAwesome icon based on the platform
  const getPlatformIcon = (platform) => {
    switch (platform) {
      case "twitter":
        return "fa-brands fa-x-twitter";
      case "discord":
        return "fa-brands fa-discord";
      case "telegram":
        return "fa-brands fa-telegram";
      case "website":
        return "fa-solid fa-globe";
      default:
        return null; // No icon for other platforms
    }
  };

  return (
    <div className="m-8">
      {publicKey ? (
        <>
          <div className="flex text-2xl font-bold mb-4">Presale</div>
          {presale.length > 0 ? (
            <div className="flex flex-wrap gap-4">
              {presale.map((d) => (
                <div
                  key={d.slug}
                  className="relative lg:basis-1/3-gap-4 md:basis-1/2-gap-4 w-full
            border border-lght bg-drk/30 backdrop-blur-sm 
           hover:border-a1 hover:border rounded-lg"
                >
                  <div className="text-xs gap-2 flex justify-end p-0.5 mr-2">
                    {d.socials.map((s) => (
                      <a
                        key={s.platform}
                        target="_blank"
                        rel="noreferrer"
                        href={s.url}
                        className="rounded-full p-1"
                      >
                        <FontAwesomeIcon icon={getPlatformIcon(s.platform)} />
                      </a>
                    ))}
                  </div>
                  <Link to={d.slug}>
                    <div className="flex bg-drk/30 justify-center px-8 py-4 rounded-t-lg">
                      <img
                        src={d.logo}
                        alt="logo"
                        className="w-52 rounded-full"
                      />
                    </div>
                    <div className="p-2 space-y-1">
                      <div
                        className="flex-auto px-3 py-2 space-y-1 text-lg font-medium 
                  line-clamp-1 break-all"
                      >
                        {d.name}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          ) : (
            <>
              {status === "loading" ? (
                <div className="flex">
                  <div className="animate-pulse items-center flex flex-col bg-drk/30 backdrop-blur-sm rounded-md lg:basis-1/3-gap-4 md:basis-1/2-gap-4 w-full">
                    <div className="rounded-full bg-slate-700 w-52 h-52 m-4 mx-8"></div>
                    <div className="h-2 bg-slate-700 rounded w-2/3 p-1 m-4"></div>
                    <div className="h-2 bg-slate-700 rounded w-2/3 p-1 m-4"></div>
                  </div>
                </div>
              ) : (
                <>No active presales. Come back later.</>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <WalletMultiButton />
        </>
      )}
    </div>
  );
}
