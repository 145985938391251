import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/logo.gif";

const Home = () => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [wallet, setWallet] = useState("");
  const [status, setStatus] = useState();

  useEffect(() => {
    fetch(`/api`);
  }, []);

  const handleCheckboxChange = () => {
    setIsButtonEnabled(!isButtonEnabled);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    // Validation regex
    const validationRegex = /^[a-zA-Z0-9_]+$/;

    if (!validationRegex.test(value) && value !== "") {
      // Invalid input, don't update the state
      return;
    }
    setWallet(value);
  };

  async function submit() {
    if (wallet) {
      try {
        setStatus("Processing");
        let url = `/api/airdrop/wl`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            CS_KEY: process.env.REACT_APP_CS_KEY,
          },
          body: JSON.stringify({
            slug: "coynshop",
            wallet: [wallet],
          }),
        });
        const data = await response.json();

        if (!response.ok) {
          setStatus("Failed");
          setTimeout(() => {
            setStatus("");
          }, 6000);
          // If response.ok is false, it means there was an HTTP error (status in the range 400-599)
          throw new Error(`${data.message}`);
        } else {
          setWallet("");
          setStatus("Completed");
          setTimeout(() => {
            setStatus("");
          }, 6000);
        }
      } catch (error) {
        setStatus("Failed");
        setTimeout(() => {
          setStatus("");
        }, 6000);
      }
    } else {
      setStatus("Failed");
      setTimeout(() => {
        setStatus("");
      }, 6000);
    }
  }

  return (
    <div className="flex flex-col justify-center items-center align-center w-full p-5">
      <div className="flex flex-col justify-center items-center align-center  p-5 rounded-lg">
        <div>
          <img src={logo} alt="logo" />
        </div>
        <div className="text-2xl mb-4">coming soon</div>

        <div className="text-md">
          <a
            href="https://twitter.com/intent/follow?screen_name=coyndotshop"
            target="_blank"
            rel="noreferrer"
            className="px-4 py-1 hover:bg-a2/60 bg-a2 items-center flex align-center">
            <FontAwesomeIcon
              icon={"fa-brands fa-x-twitter"}
              size="sm"
              className="mr-2"
            />
            follow @coyndotshop
          </a>
        </div>
        <div className="my-2">
          <label className="flex items-center text-sm text-t2">
            <input
              type="checkbox"
              className="form-checkbox mr-2 text-xs w-2"
              checked={isButtonEnabled}
              onChange={handleCheckboxChange}
            />
            i am a follower of coyndotshop
          </label>
        </div>
        <div className="flex flex-wrap gap-4 items-center align-center justify-center w-full">
          <div>
            <input
              type="text"
              name="slug"
              placeholder="your sol burner"
              value={wallet}
              onChange={handleChange}
              disabled={!isButtonEnabled}
              className="w-full rounded-sm border border-lght px-2 py-1 text-black"
              required
            />
          </div>
          <button
            className={`py-1  px-5 flex items-center 
          ${
            isButtonEnabled
              ? "bg-a1 hover:bg-a1/60 "
              : "bg-a1/40 text-t1 cursor-not-allowed"
          }
          duration-500 ease-in-out transition-all`}
            disabled={!isButtonEnabled}
            onClick={submit}>
            {status === "Processing" ? (
              <>
                <FontAwesomeIcon
                  icon="fa-solid fa-spinner"
                  spin
                  size="sm"
                  className="mr-4 font-bold"
                />
                PROCESSING
              </>
            ) : (
              <>
                gib coyn
                <FontAwesomeIcon
                  icon="fa-solid fa-parachute-box"
                  className="ml-3"
                  size="xs"
                />
              </>
            )}
          </button>
        </div>
        {status === "Completed" && (
          <div className="fixed bottom-4 right-4 flex rounded-lg border-drk border">
            <div className="bg-gr/50 py-3 px-4 rounded-l-lg flex items-center">
              <FontAwesomeIcon icon="fa-solid fa-check" size="lg" />
            </div>
            <div
              className="px-3 py-4 bg-drk/30 backdrop-blur-sm 
            rounded-r-lg flex justify-between items-center w-full ">
              <div className="px-2">well done. come back later.</div>
              <button onClick={() => setStatus(null)}>
                <FontAwesomeIcon icon="fa-solid fa-xmark" />
              </button>
            </div>
          </div>
        )}
        {status === "Failed" && (
          <div className="fixed bottom-4 right-4 flex rounded-lg border-drk border">
            <div className="bg-rd/50 py-3 px-4 rounded-l-lg flex items-center">
              <FontAwesomeIcon icon="fa-solid fa-xmark" size="lg" />
            </div>
            <div
              className="px-3 py-4 bg-drk/30 backdrop-blur-sm 
            rounded-r-lg flex justify-between items-center w-full ">
              <div className="px-2">failed. better luck next time.</div>
              <button onClick={() => setStatus(null)}>
                <FontAwesomeIcon icon="fa-solid fa-xmark" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
